/*.bodyfile {*/
/*    width: 210mm;*/
/*    height: 297mm;*/
/*    margin: 0 auto; !* Centrer le contenu sur la page *!*/
/*    padding: 20mm; !* Ajouter une marge intérieure de 20mm *!*/
/*}*/
/*.firstitle{*/
/*    color:black;*/
/*    text-decoration: underline;*/
/*}*/
/*.sencondtitle{*/
/*    color:gray;*/
/*    text-decoration: underline;*/
/*}*/

/*.thirdtitle{*/
/*    color:gray;*/
/*    text-decoration: underline;*/
/*    font-size: 10px;*/
/*}*/


/*.objectif{*/
/*    border-collapse: collapse;*/
/*    width: 113%;*/
/*    border: 0.5px solid gray;*/
/*    margin-bottom: 5%;*/
/*    padding: 2%;*/
/*} */

/*.conclusion{*/
/*    border-collapse: collapse;*/
/*    width: 113%;*/
/*    border: 0.5px solid gray;*/
/*    margin-top: 2%;*/
/*    padding: 3%;*/
/*}*/

/*.Jtable{*/
/*    border-collapse: collapse;*/
/*    width: 50%;*/
/*    border: 0.5px solid gray;*/
/*}*/

/*.Jhead{*/
/*    border: 0.5px solid gray;*/
/*    width: 100%;*/
/*}*/

/*.headtitle, .headtable {*/
/*    display: inline-block;*/
/*    vertical-align: top;*/
/*    !* Ajoutez vertical-align: top; pour aligner le contenu en haut si nécessaire *!*/
/*}*/

/*.headtable{*/
/*   transform: translateX(500px);*/
/*}*/

/*.Jtitle{*/
/*    margin-top: 5%;*/
/*}*/


/*.line{*/
/*    border: 0.5px solid gray; !* Couleur et épaisseur de la bordure *!*/
/*    width: 100%;*/
/*    height: 20px;*/
/*}*/

/*td:nth-child(2), th:nth-child(2) {*/
/*    border-left: 0.5px solid gray;*/
/*}*/

/*td:nth-child(3), th:nth-child(3) {*/
/*    border-left: 0.5px solid gray;*/
/*}*/

/*td:nth-child(4), th:nth-child(4) {*/
/*    border-left: 0.5px solid gray;*/
/*}*/

/*.JtitleTexte{*/
/*    line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0cm;*/
/*    font-size: 12pt;*/
/*    size: 3;*/
/*    font-family: Arial,serif;*/
/*    text-align: center;*/
/*    margin: 2%;*/
/*}*/

/*.JtitleSpace{*/
/*    line-height: 100%; orphans: 0; widows: 0; margin-bottom: 0cm*/
/*}*/

/*.objtitle{*/
/*    text-decoration: underline;*/
/*}*/

/*.tdJ{*/
/*    border: 0.5px solid gray; */
/*    padding: 0cm 0.12cm;*/
/*    text-align: center;*/
/*    font-family: Arial,serif;*/
/*    size: 2;*/
/*    font-size: 9pt;*/
/*    margin-bottom: 0cm;*/
/*}*/

/*.tdItem{*/
/*    border: 0.5px solid gray; padding: 0cm 0.12cm;*/
/*    font-family: Arial,serif;*/
/*    size: 2;*/
/*    font-size: 9pt;*/
/*}*/

/*.tdJF{*/
/*    font-size: 9pt;*/
/*    size: 2;*/
/*    font-family: Arial,serif;*/
/*}*/

/*.tdJFcenter{*/
/*    font-size: 9pt;*/
/*    size: 2;*/
/*    font-family: Arial,serif;*/
/*    text-align: center;*/
/*}*/

/*.tdfp{*/
/*    text-align: center;*/
/*    margin-bottom: 0cm;*/
/*}*/

