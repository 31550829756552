.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#kt_content_container{

}

.btn.btn-orange {
  color: #fff;
  border-color: #FA981B;
  background-color: #FA981B
}

.link-orange {
  color: #FA981B
}

.link-orange:focus,
.link-orange:hover {
  color: #fca32d
}

.header .header-tabs .nav-link.active, .ant-tabs-tab-btn:active {
  color: #181c32 !important;
  background-color: #fff !important;
}

.header .header-tabs .nav-link {
  color: #fff !important;
}


.header .header-tabs .nav-link:active, .link {
  color: #000 !important;
} 

.ant-tabs-tab-active {
  background-color: #fff !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #000 !important;
  border-bottom: none !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}


.ant-tabs-tab-btn {
  border: 0;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.2rem 1.5rem;
  margin-right: 0.25rem;
}

.menu-tabs {
  position: relative;
  padding-left: 20%;
}

.menu-tabs .ant-tabs {
  position: absolute;
  top: -15.5vh;
}

.ant-tabs-content {
  margin-top: 1.5vh;
}

/*.ant-table-thead .ant-table-cell {*/
/*  background-color: #04B3CB !important;*/
/*  color: #fff !important;*/
/*}*/

.btn-primary {
  background-color: #EC6200 !important;
  color: #fff;
}

.table-strong {
  font-weight: bold !important;
}

.table-orange {
  color: #EC6200 !important;
}

.ant-btn-primary {
  background-color: #EC6200 !important;
  color: #fff;
}

.table-sm {
  font-size: .9rem;
}

.table-blue {
  color: #04B3CB;
}

.table-green {
  color: #CADB2B;
}

.table-center {
  text-align: center;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.f-size-12 {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.chart-blue {
  color: #02B0C8;
}

.chart-orange {
  color: #FA981B;
}

.chart-rose {
  color: #C72B83;
}

.chart-bg-orange,
.chart-bg-rose,
.chart-bg-green {
  color: #fff;
}

.chart-bg-green {
  background-color: #27ae60;
}

.chart-bg-orange {
  background-color: #FA981B;
}

.chart-bg-rose {
  background-color: #C72B83;
}




@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.display-drop-menu{
  display: flex !important;
  z-index: 105;
  position: absolute;
  margin: 0;
  transform: translate(-110px, 45px);
}

.menu-option{
  display: flex !important;
  z-index: 105;
  position: absolute;
  margin: 0;
  transform: translate(-45px, 40px);
}
.menu-option-selected{
  display: flex !important;
  z-index: 105;
  position: absolute;
  margin: 0;
  transform: translate(-145px, 0px);
}

.stepper-nav {
  display: flex;
}

.stepper-item {
  display: flex;
  align-items: center;
  /*transition: ;*/
}

.stepper-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease, background-color 0.2s ease;
  width: 40px;
  height: 40px;
  margin-right: 1.5rem;
  background-color: #212e48;
  border-radius: 0.475rem;
}

.stepper-check {
  display: none;
  font-size: 1rem;
}

.stepper-number {
  font-weight: 600;
  color: #0c6af3;
  font-size: 1.25rem;
}

.stepper-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepper-title {
  color: lightgrey;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 0.3rem;
}

.stepper-desc {
  color: lightgrey;
}

.stepper-nav {
  flex-direction: column;
}

.stepper-item {
  position: relative;
  margin: 0;
  padding-bottom: 2.5rem;
}

.stepper-icon {
  z-index: 1;
}

.stepper-line {
  display: block;
  content: ' ';
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: lightgrey;
}

.mr-10 {
  margin-right: 10px;
}

.app-menu-option {
  cursor: pointer;
}


.app-menu-option:hover {
  background-color: #009EF7 /*#27357e*/;
  padding: 7px;
  border-radius: 5px;
}

.app-menu-option-title {
  font-weight: bold;
  font-family: Oswald;
}

.app-menu-option-title:hover {
  color: white;
}

.app-nav-menu-option {
  cursor: pointer;
}
.app-nav-menu-option:hover {
  background-color: #009EF7;
  padding: 7px;
  border-radius: 5px;
}


.app-nav-menu-option-title {
  font-weight: bold;
  font-family: Oswald;
  font-size: 14px;
}

.app-nav-menu-option:hover .app-nav-menu-option-title {
  color: white !important;
}

.app-nav-menu-option-icon {
  color: #FDB100 ;
}

.app-nav-menu-option:hover .app-nav-menu-option-icon {
  color: #FDB100 !important;
}



/**/

.button-toolbar-left:hover {
  /*background-color: #EC6200 !important;*/
  padding: 10px;
  color: #FDB100 !important;
}

.button-toolbar-right:hover {
  /*background-color: #EC6200 !important;*/
  padding: 10px;
  color: #009EF7 !important;
}

.highlighted-row {
  background-color: #FDB100;
}




