.tablePerso {
    border-collapse: collapse;
    border: none;
    letter-spacing: 1px;
    font-size: 0.8rem;
}

.tdperso,
.thperso {
    border-left: 1px solid rgb(255, 255, 255);
    padding: 10px 20px;
}

.thperso {
    background-color: #02B1C82F;
}

.rubriques {
    border-top-left-radius: 5%;
}

.assertion {
    border-top-right-radius: 5%;
}

.tdperso {
    text-align: center;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.trperso:nth-child(even) td {
    background-color: rgb(250, 250, 250);
}

.trperso:nth-child(odd) td {
    background-color: rgb(245, 245, 245);
}


