/* TreeComponent.css */

.tree-container {
    font-family: Arial, sans-serif;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.tree-node {
    margin-bottom: 10px;
}

.tree-node-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.tree-node-header:hover {
    background-color: #e2e2e2;
}

.tree-node-header.expanded {
    background-color: #ffffff;
}

.tree-node-icon {
    margin-right: 8px;
}

.tree-node-title {
    flex-grow: 1;
    font-weight: 600;
}

.tree-node-children {
    margin-left: 20px;
    border-left: 2px solid #ccc;
    padding-left: 10px;
}

/* Styling for selected node */
.tree-node-header.selected {
    background-color: #007bff;
    color: white;
}

/* Styling for icons */
.tree-node-icon {
    font-size: 20px;
}

/* Styling for leaf nodes */
.tree-node-leaf {
    color: #6c757d;
    font-style: italic;
}

.tree-node.current {
    background-color: #ffffcc;
}

.completed-icon {
    color: green;
    margin-left: auto;
}

/* Responsive styles */
@media (max-width: 768px) {
    .tree-node-children {
        margin-left: 10px;
        border-left: 1px solid #ccc;
        padding-left: 5px;
    }
}
